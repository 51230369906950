import { DesignSystemProvider } from "@ogury/design-system";
import * as React from "react";
import { AdUnitProvider } from "./AdUnitContext";

function AppProvider({ children }: React.PropsWithChildren) {
  // Provide here the list of all the global context providers so that they will be automatically shared to all environments ie. browser and testing
  // Never include BrowserRouter as testing environment needs to use MemoryRouter
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <DesignSystemProvider>
      <AdUnitProvider>{children}</AdUnitProvider>
    </DesignSystemProvider>
  );
}

export default AppProvider;
