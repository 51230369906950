import React, { createContext, useContext, useState, ReactNode } from "react";

type AdUnitType = {
  technicalId: string;
  inArticleType?: string;
};

type AdUnitContextType = [AdUnitType | null, React.Dispatch<React.SetStateAction<AdUnitType | null>>];

const AdUnitContext = createContext<AdUnitContextType | undefined>(undefined);

export function useAdUnitContext(): AdUnitContextType {
  const context = useContext(AdUnitContext);
  if (!context) {
    throw new Error("useAdUnitContext must be used within an AdUnitProvider");
  }
  return context;
}

function useProvider(): AdUnitContextType {
  const [adUnit, setAdUnit] = useState<AdUnitType | null>(null);
  return [adUnit, setAdUnit];
}

interface AdUnitProviderProps {
  children: ReactNode;
}

export function AdUnitProvider({ children }: AdUnitProviderProps) {
  const adUnitProvider = useProvider();
  return <AdUnitContext.Provider value={adUnitProvider}>{children}</AdUnitContext.Provider>;
}
