import axios from "axios";
import { SiteData, UnitData } from "app/Pages/DemoPageOCS/Types";
import { URLService } from "../index";
import { adUnitUrlValues } from "../ReferenceService/ReferenceService";
import { NETWORK_TIMEOUT } from "../../utils/contants";

class SiteService {
  data: SiteData | undefined = undefined;

  async initializeCreative(id: string) {
    if (!this.data) {
      const response = await axios.get(URLService.getSiteURL(), { params: { creativeConsoleId: id }, timeout: NETWORK_TIMEOUT });
      this.data = response?.data?.content;
      if (this.data) {
        this.data.units = this.data?.units.map((unit: UnitData) => {
          const adUnitUrlKey = Object.values(adUnitUrlValues).find((unitUrl) => unit.technicalId.startsWith(unitUrl.uniqueId))?.key;
          if (adUnitUrlKey) {
            return { ...unit, urlKey: adUnitUrlKey };
          }

          return unit;
        });
      }
    }
    return null;
  }
}
export default new SiteService();
