import { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Layout, Radio, Tabs, Typography } from "@ogury/design-system";

import { ReferenceService } from "services";
import AdUnitsService from "services/AdUnitsService/AdUnitsService";
import { useAdUnitContext } from "context/AdUnitContext";
import { adUnitUrlValues } from "services/ReferenceService/ReferenceService";
import OguryLogo from "assets/img/Logo.svg";
import { CreativeDataProps } from "types";
import LinkImg from "assets/img/LinkImg.svg";
import { SiteData, UnitData } from "./Types";
import { MobileContent, MobileFormTab } from "./components";
import DesktopContent from "./components/DesktopContent";
import DesktopFormTab from "./components/DesktopFormTab";
import styles from "./DemoPageOCS.module.scss";

enum DEMO_PAGE_MODE {
  MOBILE = "MOBILE",
  DESKTOP = "IAB_BANNERS",
}

type Props = {
  siteData: SiteData;
  creativeData: CreativeDataProps;
  forcePreview: boolean;
};

function DemoPageOCS({ siteData, creativeData, forcePreview }: Props): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, setAdUnitContext] = useAdUnitContext();
  const adUnitUrlParam = searchParams.get(ReferenceService.urlParameters.AU) || creativeData.adUnit;

  const { t } = useTranslation();
  const desktopAdUnits = useMemo(() => (!siteData || !siteData.units ? [] : siteData.units.filter((unit) => unit.technicalId.startsWith("banner"))), [siteData]);
  const mobileAdUnits = useMemo(() => (!siteData || !siteData.units ? [] : siteData.units.filter((unit) => !unit.technicalId.startsWith("banner"))), [siteData]);
  const hasMobile = useMemo(() => mobileAdUnits.length > 0, [mobileAdUnits]);
  const hasDesktop = useMemo(() => desktopAdUnits.length > 0, [desktopAdUnits]);

  const mode = useMemo(() => {
    if (adUnitUrlParam === adUnitUrlValues.BANNER.key && hasDesktop) {
      return DEMO_PAGE_MODE.DESKTOP;
    }
    if (hasMobile) {
      return DEMO_PAGE_MODE.MOBILE;
    }
    return undefined;
  }, [adUnitUrlParam, hasDesktop, hasMobile]);

  const handleModeChange = (value: DEMO_PAGE_MODE) => {
    if (value === DEMO_PAGE_MODE.MOBILE) {
      const firstMobileAdUnit = mobileAdUnits[0]?.externalId;
      const firstMobileAdUnitUrlKey = mobileAdUnits[0]?.urlKey;

      setSearchParams({
        ...Object.fromEntries(searchParams),
        [ReferenceService.urlParameters.CID]: firstMobileAdUnit,
        [ReferenceService.urlParameters.AU]: firstMobileAdUnitUrlKey,
      });
    } else {
      const firstBannerAdUnit = desktopAdUnits[0]?.externalId;
      setSearchParams({
        ...Object.fromEntries(searchParams),
        [ReferenceService.urlParameters.CID]: firstBannerAdUnit,
        [ReferenceService.urlParameters.AU]: adUnitUrlValues.BANNER.key,
      });
    }
  };

  const templateId = useMemo(() => {
    if (siteData?.units.length > 0) {
      return siteData?.units[0].templateId;
    }
    return null;
  }, [siteData]);

  useEffect(() => {
    const units: UnitData[] = siteData?.units?.filter((unit) => unit.externalId === creativeData.creativeId);

    if (units?.length > 0) {
      const unit = units[0];
      setAdUnitContext({
        technicalId: unit.technicalId,
        inArticleType: AdUnitsService.computeInArticleType(unit.technicalId),
      });
    }
  }, [creativeData]);

  return (
    <Layout className={styles.layout}>
      <Layout.Sider width={250} className={styles.sider}>
        <div className={styles.logo}>
          <img id="logo" src={OguryLogo} alt="Ogury logo" />
        </div>
        <Radio.Group onChange={handleModeChange} value={mode} className={styles.tabSelector}>
          <Radio.Button disabled={!hasMobile} value={DEMO_PAGE_MODE.MOBILE}>
            {t("DemoShowcase.Mobile")}
          </Radio.Button>
          <Radio.Button disabled={!hasDesktop} value={DEMO_PAGE_MODE.DESKTOP}>
            {t("DemoShowcase.IabBanners")}
          </Radio.Button>
        </Radio.Group>

        <Tabs
          tabBarStyle={{ display: "none" }}
          activeKey={mode}
          items={[
            {
              key: DEMO_PAGE_MODE.MOBILE,
              children: <MobileFormTab creativeData={creativeData} adUnits={mobileAdUnits} />,
            },
            {
              key: DEMO_PAGE_MODE.DESKTOP,
              children: <DesktopFormTab siteData={siteData} adUnits={desktopAdUnits} />,
            },
          ]}
        />

        <div className={styles.siderFooter}>
          <Typography.TextLink2 href="https://www.ogury.com">
            {t("DemoShowcase.learMoreAboutOgury", "Learn more about Ogury Ads")}&nbsp;&nbsp;
            <img id="linkImg" src={LinkImg} alt="linkImg" />
          </Typography.TextLink2>
        </div>
      </Layout.Sider>
      <Layout.Content>
        <div className={styles.header}>
          <Typography.H5Strong>{siteData.name}</Typography.H5Strong>
          <div className={styles.meta}>
            <ul>
              {siteData.brand?.name && (
                <li>
                  <Typography.P2Regular>{t("DemoShowcase.brand")}</Typography.P2Regular>
                  <Typography.P2Strong>{siteData.brand?.name}</Typography.P2Strong>
                </li>
              )}
              {siteData.account?.name && (
                <li>
                  <Typography.P2Regular>{t("DemoShowcase.account")}</Typography.P2Regular>
                  <Typography.P2Strong>{siteData.account?.name}</Typography.P2Strong>
                </li>
              )}
              {templateId && (
                <li>
                  <Typography.P2Regular>{t("DemoShowcase.template")}</Typography.P2Regular>
                  <Typography.P2Strong style={{ textTransform: "capitalize" }}>{templateId}</Typography.P2Strong>
                </li>
              )}
            </ul>
          </div>
        </div>
        <Tabs
          destroyInactiveTabPane
          tabBarStyle={{ display: "none" }}
          activeKey={mode}
          className={styles.content}
          items={[
            {
              key: DEMO_PAGE_MODE.MOBILE,
              children: <MobileContent creativeData={creativeData} forcePreview={forcePreview} />,
            },
            {
              key: DEMO_PAGE_MODE.DESKTOP,
              children: <DesktopContent creativeData={creativeData} forcePreview={forcePreview} adUnits={desktopAdUnits} />,
            },
          ]}
        />
      </Layout.Content>
    </Layout>
  );
}

export default DemoPageOCS;
